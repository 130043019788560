/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, Divider, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"BATOLE, SITTER, NAROZENINY"}>
        <Column className="--menu pb--08 pt--08" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box fs--22" content={"<a href=\"https://michaelasynacovafotografie.cz\">Michaela Synáčová Fotografie </a>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

              </MenuWrapBox>

              <MenuWrapBox style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz"} use={"url"} href={"https://michaelasynacovafotografie.cz"} target={""} content={"<span style=\"font-weight: 400; color: rgb(0, 0, 0);\">home</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/foceni"} target={""} content={"<span style=\"caret-color: rgb(2, 0, 0); color: rgb(2, 0, 0); font-weight: 400;\">CENÍKY</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/info/"} use={"url"} href={"https://michaelasynacovafotografie.cz/info/"} target={""} content={"<span style=\"font-weight: 400; color: rgb(4, 0, 0);\">INFO</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/kontakt/"} use={"url"} href={"https://michaelasynacovafotografie.cz/kontakt/"} target={""} content={"<div><span style=\"font-weight: normal; color: rgb(0, 0, 0);\">KONTAKT</span><br></div>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--60 pt--60" name={"wvmqkxbg78"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--30 pt--30" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"BATOLE, SITTER,\nNAROZENINY"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"font-style: italic;\">Aranžované batolecí a sitter focení v ateliéru.<br></span><br><span style=\"font-style: italic;\">Narozeninové focení v exteriéru nebo v ateliéru.<br>Je možnost i kombinace ateliéru a exteriéru za příplatek 500,-.<br><br>Součástí balíčku jsou i fotografie s rodiči a sourozenci.</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"3500,— Kč<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"font-weight: bold;\">Co vše obdržíte? <br><br></span>&nbsp;<span style=\"font-style: italic;\">cca 40 min focení <br><br>&nbsp;15 retušovaných fotografií v elektronické podobě v barevné i černobílé variantě zaslané přes webovou galerii</span><span style=\"font-style: italic;\"><br><br>&nbsp;Každá další fotografie nad rámec balíčku &nbsp;200,-<br><br>&nbsp;Dodací doba je zhruba 4-6 týdny od Vámi zaslaných výběrů a připsání platby na účet</span><br>"}>
              </Text>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box" content={"&nbsp;STORY"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"5000,— Kč<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"font-weight: bold;\">Co vše obdržíte? <br><br></span>&nbsp;<span style=\"font-style: italic;\">cca 60 min focení <br>v EXTERIÉRU<br><br>&nbsp;25 retušovaných fotografií v elektronické podobě v barevné i černobílé variantě zaslané přes webovou galerii</span><span style=\"font-style: italic;\"><br><br>&nbsp;Každá další fotografie nad rámec balíčku &nbsp;200,-<br><br>&nbsp;Dodací doba je zhruba 4-6 týdny od Vámi zaslaných výběrů a připsání platby na účet</span><br>"}>
              </Text>

              <Divider >
              </Divider>

              <Subtitle className="subtitle-box" content={"FULL STORY"}>
              </Subtitle>

              <Subtitle className="subtitle-box" content={"6000,— Kč<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"font-weight: bold;\">Co vše obdržíte? <br><br></span>&nbsp;<span style=\"font-style: italic;\">cca 60 min focení <br>v EXTERIÉRU<br><br>&nbsp;Cca 50 základně fotografií v elektronické podobě v barevné i černobílé variantě zaslané přes webovou galerii</span><span style=\"font-style: italic;\"><br><br>Fotografie vybírá FOTOGRAF!<br><br>&nbsp;Dodací doba je zhruba 4-6 týdny od připsání platby na účet</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pew1t2xjz2"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/449aaeaf44d9497f9de1fbc7587ae3ea_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/449aaeaf44d9497f9de1fbc7587ae3ea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/449aaeaf44d9497f9de1fbc7587ae3ea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/449aaeaf44d9497f9de1fbc7587ae3ea_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/449aaeaf44d9497f9de1fbc7587ae3ea_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/449aaeaf44d9497f9de1fbc7587ae3ea_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/f0a857b2420245d4be03f35a3e1a3a56_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/f0a857b2420245d4be03f35a3e1a3a56_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/f0a857b2420245d4be03f35a3e1a3a56_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/f0a857b2420245d4be03f35a3e1a3a56_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/f0a857b2420245d4be03f35a3e1a3a56_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/e449b725510a4c5793bb9be471d1e452_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/e449b725510a4c5793bb9be471d1e452_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/e449b725510a4c5793bb9be471d1e452_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/e449b725510a4c5793bb9be471d1e452_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/e449b725510a4c5793bb9be471d1e452_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/e22eef0b695e4e26a69997b16c4b3ab1_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/e22eef0b695e4e26a69997b16c4b3ab1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/e22eef0b695e4e26a69997b16c4b3ab1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/e22eef0b695e4e26a69997b16c4b3ab1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/e22eef0b695e4e26a69997b16c4b3ab1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/e22eef0b695e4e26a69997b16c4b3ab1_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lqa8m8jw2b"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/430d1d1e1e0f45a0a45bdf0e9557be0c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/430d1d1e1e0f45a0a45bdf0e9557be0c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/430d1d1e1e0f45a0a45bdf0e9557be0c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/430d1d1e1e0f45a0a45bdf0e9557be0c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/430d1d1e1e0f45a0a45bdf0e9557be0c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/430d1d1e1e0f45a0a45bdf0e9557be0c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/60de89f197074cd98c2a5c0d62a8883f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/60de89f197074cd98c2a5c0d62a8883f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/60de89f197074cd98c2a5c0d62a8883f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/60de89f197074cd98c2a5c0d62a8883f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/60de89f197074cd98c2a5c0d62a8883f_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/f8ebd1524f54444796d7b70a742d19c4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/f8ebd1524f54444796d7b70a742d19c4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/f8ebd1524f54444796d7b70a742d19c4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/f8ebd1524f54444796d7b70a742d19c4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/f8ebd1524f54444796d7b70a742d19c4_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/4ef6c3a3a25d4fe2841ef9d835db81dd_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/4ef6c3a3a25d4fe2841ef9d835db81dd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/4ef6c3a3a25d4fe2841ef9d835db81dd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/4ef6c3a3a25d4fe2841ef9d835db81dd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/4ef6c3a3a25d4fe2841ef9d835db81dd_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/4ef6c3a3a25d4fe2841ef9d835db81dd_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80">
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn1 btn-box--cColor2" url={"https://michaelasynacovafotografie.cz/info/"} use={"url"} href={"https://michaelasynacovafotografie.cz/info/"} content={"INFO K FOCENÍ ZDE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={false} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--20 subtitle-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Michaela Synáčová Fotografie<br></span><br>"}>
              </Subtitle>

              <Title className="title-box fs--26 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">NEWBORN A RODINNÁ FOTOGRAFKA</span>"}>
              </Title>

              <Text className="text-box fs--14 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">5.května 653, Blovice</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">+420 739 449 891</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert mt--0" content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">michaela.synacova.fotografie@gmail.com</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn1 btn-box--sbtn2 fs--16" href={"https://www.facebook.com/michaelasynacova.fotografie"} content={"<span style=\"color: rgb(0, 0, 0);\">facebook</span>"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 fs--16" href={"https://www.instagram.com/michaela.synacova.fotografie/"} content={"<span style=\"color: rgb(0, 0, 0);\">instagram</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}